import { BaseIconProps } from '../types';

const Man = (props: BaseIconProps) => (
  <svg viewBox="0 0 19 20" width="19" height="20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeWidth="2"
      d="m18 16.3-4.537-4.5m4.537 0-4.537 4.5M13.463 19H2.815a1.827 1.827 0 0 1-1.36-.609 1.796 1.796 0 0 1-.441-1.414l.354-2.812a2.693 2.693 0 0 1 .9-1.69A2.735 2.735 0 0 1 4.07 11.8h.319M8.926 1a4.556 4.556 0 0 0-3.208 1.318A4.481 4.481 0 0 0 4.39 5.5c0 1.193.478 2.338 1.329 3.182A4.556 4.556 0 0 0 8.926 10a4.556 4.556 0 0 0 3.208-1.318 4.481 4.481 0 0 0 1.33-3.182 4.481 4.481 0 0 0-1.33-3.182A4.556 4.556 0 0 0 8.926 1v0Z"
    />
  </svg>
);

export default Man;
