import styles from './CrmContactCard.module.scss';

import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import cn from 'classnames';

import { Avatar, AvatarProps, AvatarSize } from 'shared/ui/Avatar/Avatar';
import { formatDate, getUserPhoto } from 'utils';
import LidEmoji from 'components/LidEmoji';

export interface CrmContactCardProps {
  contact: any;
  onClick?: (userDto: any) => void;
}

function CrmContactCard({ contact, onClick }: CrmContactCardProps): JSX.Element | null {
  const userName = useMemo(() => contact.userName, [contact]);
  const isIgnored = useMemo(() => contact.isIgnored, [contact]);
  const userPhoto = useMemo(() => getUserPhoto(contact), [contact]);
  const lastMessageText = useMemo(() => contact.lastMessageText, [contact]);
  const lastMessageDate = useMemo(() => contact.lastMessageDate, [contact]);
  const navigate = useNavigate();

  const avatarProps = useMemo<AvatarProps>(() => {
    return {
      username: userName,
      profilePictureUrl: userPhoto,
      size: !isIgnored ? AvatarSize.Payment : AvatarSize.Small,
    };
  }, [userName, userPhoto, isIgnored]);

  const onCardClick = () => {
    if (onClick) {
      onClick(contact);
    } else {
      navigate(`/crm/${contact.id}`);
    }
  };

  return (
    <div onClick={onCardClick} className={cn(styles.card, { [styles.ignore]: isIgnored })}>
      <div className={styles.cardWrapper}>
        <Avatar {...avatarProps} />

        <div className={styles.wrap}>
          <div className={styles.inner}>
            <div className={styles.username}>{userName}</div>
          </div>

          <LidEmoji
            needsAttention={contact.needsAttention}
            responseUrgency={contact.responseUrgency}
            readiness={contact.readiness}
          />

        </div>
      </div>
      <div className={styles.text}>
        <div className={styles.message}>{lastMessageText}</div>
        <div className={styles.date}>
          {formatDate(new Date(lastMessageDate))}
        </div>
      </div>
    </div>
  );
}

export default React.memo(CrmContactCard);
