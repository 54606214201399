import { Readiness, ResponseUrgency } from 'dtos';
import styles from './LidEmodji.module.scss';

interface LidEmojiProps {
  needsAttention: boolean;
  responseUrgency: ResponseUrgency;
  readiness: Readiness;
}

const LidEmoji = ({ needsAttention, responseUrgency, readiness }: LidEmojiProps) => {

  const responseUrgencyEmoji = responseUrgency == ResponseUrgency.High ? '🔥' : '💥';

  return (
    <div className={styles.emoji}>
      {needsAttention && (
        <div className={styles.responseNeeded}>
          ⚽️
        </div>
      )}
      {responseUrgency != ResponseUrgency.Low && (
        <div className={styles.responseUrgency}>
          {responseUrgencyEmoji}
        </div>
      )}
      {readiness == Readiness.Ready && (
        <div className={styles.readiness}>
          💵
        </div>
      )}
    </div>
  )
}

export default LidEmoji;
