import styles from './Payment.module.scss';

import React, { Ref, useMemo } from 'react';
import { Link, generatePath } from 'react-router-dom';

import { PAYMENT } from 'pages/paths';

import Details from './Details';

import { PaymentForListDto, PaymentForListDtoV4, PaymentParticipantRole, UserDto } from 'dtos';

import { Avatar, AvatarProps, AvatarSize } from 'shared/ui/Avatar/Avatar';
import { useCurrentUser } from 'shared/hooks/useCurrentUser';
import PaymentRoleIndicator from './PaymentRoleIndicator';
import { getUserName, getUserPhoto } from 'utils';
import Telegram from '../icons/Telegram';
import checkIsWebApp from '../../utils/checkIsWebApp';

export type PaymentProps = {
  paymentRef?: Ref<HTMLAnchorElement>;
  payment: PaymentForListDtoV4;
};

function Payment({ paymentRef, payment }: PaymentProps): JSX.Element {
  const user = useCurrentUser();

  const paymentProvider: UserDto = payment.provider;
  const isMeProvider: boolean = user?.id === paymentProvider.id;

  const primaryUser: keyof PaymentForListDto = isMeProvider
    ? 'requester'
    : 'provider';
  const role =
    primaryUser === 'requester'
      ? (PaymentParticipantRole.Customer.toLowerCase() as Lowercase<PaymentParticipantRole.Customer>)
      : primaryUser;
  const userName = useMemo(() => getUserName(payment[primaryUser]), [payment, primaryUser]);
  const userPhoto = useMemo(() => getUserPhoto(payment[primaryUser]), [payment, primaryUser]);

  type AvatarPropsWithoutOnClick = Omit<AvatarProps, 'onClick' | 'onChangePhoto' | 'customPhotoUrl' | 'tgChatLink' | 'waChatLink'>;
  const avatarProps: Required<AvatarPropsWithoutOnClick> = useMemo(() => {
    return {
      username: userName,
      profilePictureUrl: userPhoto,
      dotIndicator: <PaymentRoleIndicator role={role} />,
      size: AvatarSize.Payment,
      className: styles.avatar,
    };
  }, [role, userName, userPhoto]);

  const isWebApp = checkIsWebApp();

  return (
    <div className={styles.payment}>
      <Link ref={paymentRef} to={generatePath(PAYMENT, { id: payment.id })} className={styles.paymentInfo}>
        <div className={styles.container}>
          <Avatar {...avatarProps} />

          <Details
            payment={payment}
            username={avatarProps.username}
            role={role}
          />
        </div>
      </Link>
      {payment.telegramInviteLink && (
        <Link
          to={isWebApp ? payment.telegramInviteLink : (payment.deepLinkToChat || payment.telegramInviteLink)}
          className={styles.telegramButton}
        >
          <Telegram className={styles.telegramIcon} />
        </Link>
      )}
    </div>
  );
}

export default React.memo(Payment);
