import styles from './index.module.scss';

import cn from 'classnames';
import mime from 'mime-db';

import IconDocument from 'components/icons/Document';

import {MessageDto} from 'dtos';

import { convertBytes } from 'utils';

type Props = {
  message: MessageDto;
  className?: string;
};

const urlReg = new RegExp(
  '([a-zA-Z0-9]+://)?([a-zA-Z0-9_]+:[a-zA-Z0-9_]+@)?([a-zA-Z0-9.-]+\\.[A-Za-z]{2,4})(:[0-9]+)?(/.*)?',
  'ig'
);
function replaceUrl(str: string) {
  const urlInfo = [...str.matchAll(urlReg)];
  if (urlInfo.length === 0) {
    return str;
  }

  urlInfo.forEach(([u]) => {
    str = str.replace(
      u,
      `<a href="${u}" target="_blank" style="text-decoration: underline;">${u}</a>`
    );
  });

  return str;
}

function Message(props: Props) {
  const {
    timestamp,
    stringRepresentation = '&nbsp;',
    fromMe,
    messageType,
    attachmentUrl,
    attachmentMetadata = {},
  } = props.message;
  const time = new Date(timestamp).toLocaleTimeString([], {
    hour: '2-digit',
    minute: '2-digit',
    hour12: false,
  });

  const { width, height } = attachmentMetadata;

  const text = replaceUrl(stringRepresentation || '');

  let content;

  switch (messageType) {
    case 'IMAGE': {
      if (attachmentUrl) {
        content = (
          <div style={{ aspectRatio: `${width} / ${height}` }}>
            <img className={styles.image} src={attachmentUrl} />
          </div>
        );
      }

      break;
    }
    case 'LOCATION': {
      content = (
        <div
          className={styles.text}
          dangerouslySetInnerHTML={{ __html: attachmentUrl ? replaceUrl(attachmentUrl) : text }}
        ></div>
      );
      break;
    }
    case 'DOCUMENT': {
      const extensions = attachmentMetadata.mimeType ? mime[attachmentMetadata.mimeType].extensions : null;
      const extension = Array.isArray(extensions) ? extensions[0] : '';
      content = (
        <a href={attachmentUrl} target='_blank' className={styles.docLink}>
          <IconDocument className={styles.iconDocument} />
          <div className={styles.docLinkTextWrap}>
            {attachmentMetadata.title && (
              <div className={cn(styles.text, styles.docLinkText)}>
                {attachmentMetadata.title}.{extension}
              </div>
            )}
            {attachmentMetadata.size && (
              <div className={styles.docLinkSize}>
                {convertBytes(Number(attachmentMetadata.size))}
              </div>
            )}
          </div>
        </a>
      );
      break;
    }
    case 'TEXT': {
      content = <div className={styles.text} dangerouslySetInnerHTML={{ __html: text }}></div>;
      break;
    }
    default: {
      content = <div className={styles.text} dangerouslySetInnerHTML={{ __html: text }}></div>;
      break;
    }
  }

  return (
    <div className={cn(styles.message, { [styles.me]: fromMe, [styles.imageWrapper]:  messageType === 'IMAGE'})}>
      {content}
      <div className={styles.time}>{time}</div>
    </div>
  );
}

export default Message;
