import styles from './CrmContact.module.scss';

import cn from 'classnames';

import { useEffect, useRef, useState, type ChangeEvent } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { queryClient } from 'shared/config/reactQuery';

import Input from 'components/Input/Input';
import Button from 'components/Button/Button';
import Spinner from 'components/Spinner/Spinner';
import Message from 'components/Message';
import HideIcon from 'components/icons/Hide';
import ShowIcon from 'components/icons/Show';
import { groupListByCreatedDate } from 'utils';

import { AvatarSize, Avatar } from 'shared/ui/Avatar/Avatar';
import {
  useGetCrmContactByIdQuery,
  useSwitchCrmContactByIdMutation,
  useSendMessageMutation,
  useLazyGetNextUserQuery,
} from 'services/crm';
import { useMemo } from 'react';
import InfoIcon from 'components/icons/Info';
import CloseIcon from 'components/icons/Close';
import LidEmoji from 'components/LidEmoji';

function CrmContact(): JSX.Element | null {
  const { id } = useParams();
  const navigate = useNavigate();
  const ref = useRef<HTMLDivElement>(null);
  const [isHiddenMotivation, setIsHiddenMotivation] = useState(true);

  const crmContactId: number = Number(id);

  const { data, isLoading } = useGetCrmContactByIdQuery(crmContactId, {
    skip: !crmContactId,
    pollingInterval: 60000,
  });
  const [sendMessage, { isLoading: isLoadingSendMessage }] = useSendMessageMutation();
  const [getNextUser] = useLazyGetNextUserQuery();

  const [messageText, setMessageText] = useState<string>('');

  const changeMessage = (e: ChangeEvent<HTMLInputElement>): void => {
    setMessageText(e.target.value);
  };

  const handleSubmit = () => {
    sendMessage({ contactId: crmContactId, message: messageText })
      .then(() => {
        return getNextUser(crmContactId).unwrap();
      })
      .then((nextUserId) => {
        nextUserId ? navigate(`/crm/${nextUserId}`) : navigate('/crm');
      })
      .catch(() => {
        navigate('/crm');
      });
      setMessageText('');
  };

  const crmContact = useMemo(() => {
    if (data == null) {
      return null;
    }

    return {
      ...data,
      waChatLink: `https://wa.me/${data.phoneNumber}`,
    };
  }, [data]);

  const messages = useMemo(() => {
    if (!crmContact) {
      return [];
    }

    const temp = crmContact.messages.map(item => ({ ...item, createdAt: item.timestamp }));

    return groupListByCreatedDate(temp || [], true, true);
  }, [crmContact]);

  const [switchContact] = useSwitchCrmContactByIdMutation();
  const handleSwitchClick = async (id: number) => {
    await switchContact(id).then(() => {
      queryClient.removeQueries(['crm'], { exact: false });
      navigate(-1);
    });
  };

  const handleOpenMotivation = () => {
    setIsHiddenMotivation(!isHiddenMotivation);
  }

  useEffect(() => {
    if (ref.current) {
      ref.current.scrollIntoView({ block: 'end' });
    }
  }, [messages]);

  if (isLoading) {
    return <Spinner className={styles.spinner} />;
  }

  if (!crmContact) {
    return null;
  }

  return (
    <div className={styles.friend} ref={ref}>
      <div className={styles.user}>
        <Avatar
          username={crmContact.userName}
          profilePictureUrl={crmContact.profilePictureUrl}
          waChatLink={crmContact.waChatLink}
          size={AvatarSize.Payment}
        />
        <div className={styles.userInfo}>
          <div>{crmContact.userName}</div>
          <div>{crmContact.phoneNumber}</div>
        </div>

        <LidEmoji
          needsAttention={crmContact.needsAttention}
          responseUrgency={crmContact.responseUrgency}
          readiness={crmContact.readiness}
        />

        <div className={styles.hideIcon} onClick={() => handleSwitchClick(crmContact.id)}>
          {crmContact.isIgnored ? <ShowIcon /> : <HideIcon />}
        </div>
        {crmContact.motivation && <div onClick={handleOpenMotivation}>
          <InfoIcon />
        </div>}
      </div>
      <div className={cn(styles.motivation, isHiddenMotivation && styles.hidden)}>
        <div className={styles.closeMotivation} onClick={handleOpenMotivation}>
          <CloseIcon className={styles.closeIcon}/>
        </div>
        {crmContact.motivation}
      </div>

      {messages.map(m => {
        if (typeof m === 'string') {
          return (
            <h3 key={m} className={styles.date}>
              {m}
            </h3>
          );
        }

        return <Message key={m.id} message={m} />;
      })}

      <div className={styles.bottomPanel}>
        <Input
          name="send-message"
          value={messageText}
          onChange={changeMessage}
          className={styles.inputWrapper}
        />
        <Button
          onClick={handleSubmit}
          className={styles.button}
          disabled={isLoadingSendMessage}
          isLoading={isLoadingSendMessage}
        >
          {'>'}
        </Button>
      </div>
    </div>
  );
}

export default CrmContact;
