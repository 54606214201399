import React from 'react';

import { InfiniteCrmList } from 'modules/InfiniteCrmList';

import { Layout } from 'components/Layout/Layout';

type CrmContentProps = {
  searchString?: string;
};

export function CrmContent(props: CrmContentProps): JSX.Element {
  const { searchString } = props;

  return (
    <Layout.Content unsetBottomPadding>
      <InfiniteCrmList />

    </Layout.Content>
  );
}
