import styles from './InfiniteCrmList.module.scss';

import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useInView } from 'react-intersection-observer';

import { InfiniteList } from 'components/InfiniteList';
import CrmContactCard from 'components/CrmContactCard';

import { useGetInfiniteCrm } from './api/useGetInfiniteCrm';

import SkeletonWithWrapper from 'shared/ui/SkeletonWithWrapper';
import { getIndexOfLastListElement } from 'shared/lib/arrays';
import { useQueryClient } from '@tanstack/react-query';

export function InfiniteCrmList(): JSX.Element {
  const { ref, inView } = useInView();
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const { data, fetchNextPage, isLoading, isFetchingNextPage } =
    useGetInfiniteCrm();

  const contacts = useMemo(() => {
    const allContacts = data?.pages.map((p) => p.content) || [];
    return allContacts.flat();
  }, [data?.pages]);

  useEffect(() => {
    if (inView) {
      fetchNextPage();
    }
  }, [inView, fetchNextPage]);

  useEffect(() => {
    queryClient.removeQueries(['crm'], { exact: false });
  }, []);

  const indexOfLastListElement = getIndexOfLastListElement(contacts);

  return (
    <InfiniteList
      limit={20}
      loading={isLoading}
      loadingNextPage={isFetchingNextPage}
      listClassName={styles.contacts}
      loaderItem={<SkeletonWithWrapper height={64} />}
      emptyListPlaceholder={t('page.friends.empty')}
    >
      {contacts.map((c, idx) => (
        <li key={c.id} ref={indexOfLastListElement === idx ? ref : undefined}>
            <CrmContactCard contact={c} />
        </li>
      ))}
    </InfiniteList>
  );
}
