import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReauth } from '../fetchBaseQueryWithAuth';
import { FriendsParams } from 'api/balances/types';
import { WAContactExtendedDto, PageWAContactDto, WAContactDto } from 'dtos';

type PageParams = {
  page?: number;
  limit?: number;
};

type MessageParams = {
  contactId: number;
  message: string;
};

export const INITIAL_PAGE: FriendsParams['page'] = 1;
export const INITIAL_LIMIT: FriendsParams['limit'] = 20;

export const crmApi = createApi({
  reducerPath: 'crmApi',
  baseQuery: baseQueryWithReauth(true),
  tagTypes: ['CRM'],
  endpoints: (builder) => ({
    getCrmContacts: builder.query<PageWAContactDto, PageParams>({
      query: (params) => ({
        url: `/crm/contacts`,
        params,
      }),
      providesTags: (result) =>
        result?.content
          ? [...result.content.map((contact: WAContactDto) => ({ type: 'CRM' as const, id: contact.id })), 'CRM']
          : ['CRM'],
    }),

    getCrmContactById: builder.query<WAContactExtendedDto, number>({
      query: (contactId) => ({
        url: `/crm/contacts/${contactId}`,
      }),
      providesTags: (_result, _error, contactId) => [{ type: 'CRM', id: contactId }],
    }),

    switchCrmContactById: builder.mutation<void, number>({
      query: (contactId) => ({
        url: `/crm/contacts/ignore`,
        params: {contactId},
        method: 'POST',
      }),
      invalidatesTags: (_result, _error, contactId) => [{ type: 'CRM', id: contactId }],
    }),

    sendMessage: builder.mutation<void, MessageParams>({
      query: (params) => ({
        url: `/crm/send-message`,
        params,
        method: 'POST',
      }),
      invalidatesTags: (_result, _error, {contactId}) => [{ type: 'CRM', id: contactId }],
    }),

    getNextUser: builder.query<number | null, number>({
      query: (contactId) => ({
        url: '/crm/contacts/next',
        params: {
          currentContactId: contactId,
        },
      }),
    }),
  }),
});

export const {
  useLazyGetCrmContactsQuery,
  useGetCrmContactByIdQuery,
  useSwitchCrmContactByIdMutation,
  useSendMessageMutation,
  useLazyGetNextUserQuery,
} = crmApi;
