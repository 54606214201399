import styles from './index.module.scss';
import { useTranslation } from 'react-i18next';

import RefreshIcon from 'components/icons/Refresh';
import { Layout } from 'components/Layout/Layout';

export function CrmHeader() {
  const { t } = useTranslation();

  const handleRefreshClick = () => {
    window.location.reload();
  };

  return (
    <Layout.TopBar unsetRowGap>
      <Layout.Title className={styles.title}>
        {t('page.crm.title')}
        <div onClick={handleRefreshClick} className={styles.icon}>
          <RefreshIcon />
        </div>
      </Layout.Title>
    </Layout.TopBar>
  );
}
