import { BaseIconProps } from '../types';

const WhatsApp = (props: BaseIconProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="256" height="256" viewBox="0 0 256 256" {...props}>
    <g
      style={{
        stroke: "none",
        strokeWidth: "0",
        strokeDasharray:"none",
        strokeLinecap:"butt",
        strokeLinejoin:"miter",
        strokeMiterlimit:"10",
        fill:"none",
        fillRule:"nonzero",
        opacity:"1"
      }}
        transform="matrix(2.81 0 0 2.81 1.407 1.407)"
    >
      <circle
        cx="45"
        cy="45"
        r="45"
        style={{
          stroke:"none",
          strokeWidth:"1",
          strokeDasharray:"none",
          strokeLinecap:"butt",
          strokeLinejoin:"miter",
          strokeMiterlimit:"10",
          fill:"#2ab540",
          fillRule:"nonzero",
          opacity:"1",
        }}
      />
      <path
        d="M16.138 44.738a28.911 28.911 0 0 0 3.869 14.485l-4.112 15.013 15.365-4.029a28.958 28.958 0 0 0 13.85 3.527h.012c15.973 0 28.976-12.999 28.983-28.974.003-7.742-3.01-15.022-8.481-20.498-5.472-5.476-12.749-8.494-20.502-8.497-15.976 0-28.977 12.997-28.984 28.973m9.15 13.728-.574-.911a24.013 24.013 0 0 1-3.683-12.816c.005-13.278 10.811-24.081 24.099-24.081a23.937 23.937 0 0 1 17.031 7.062 23.942 23.942 0 0 1 7.05 17.037c-.006 13.279-10.812 24.083-24.09 24.083h-.009a24.071 24.071 0 0 1-12.261-3.357l-.88-.522-9.118 2.391 2.435-8.886zm19.834 15.268s-.001 0 0 0"
        style={{
          stroke:"none",
          strokeWidth:"1",
          strokeDasharray:"none",
          strokeLinecap:"butt",
          strokeLinejoin:"miter",
          strokeMiterlimit:"10",
          fill:"#fff",
          fillRule:"nonzero",
          opacity:"1",
        }}
      />
      <path
        d="M37.878 32.624c-.543-1.206-1.113-1.23-1.63-1.251-.422-.018-.905-.017-1.388-.017-.483 0-1.268.181-1.931.906-.664.725-2.535 2.477-2.535 6.039 0 3.563 2.595 7.006 2.957 7.49.362.483 5.01 8.028 12.37 10.931 6.118 2.412 7.362 1.933 8.69 1.812 1.328-.121 4.285-1.751 4.888-3.442.604-1.691.604-3.14.423-3.443-.181-.302-.664-.483-1.388-.845-.724-.362-4.285-2.114-4.948-2.356-.664-.241-1.147-.362-1.63.363-.483.724-1.87 2.355-2.292 2.838-.422.484-.845.544-1.569.182-.724-.363-3.057-1.127-5.824-3.594-2.153-1.92-3.606-4.29-4.029-5.015-.422-.724-.045-1.116.318-1.477.325-.324.724-.846 1.087-1.268.361-.423.482-.725.723-1.208.242-.483.121-.906-.06-1.269-.181-.363-1.588-3.944-2.232-5.376"
        style={{
          stroke:"none",
          strokeWidth:"1",
          strokeDasharray:"none",
          strokeLinecap:"butt",
          strokeLinejoin:"miter",
          strokeMiterlimit:"10",
          fill:"#fff",
          fillRule:"nonzero",
          opacity:"1",
        }}
      />
    </g>
  </svg>
);

export default WhatsApp;
