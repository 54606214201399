import { useInfiniteQuery } from '@tanstack/react-query';
import { INITIAL_LIMIT, INITIAL_PAGE, useLazyGetCrmContactsQuery } from 'services/crm';

export const useGetInfiniteCrm = () => {
  const [getCrmContacs] = useLazyGetCrmContactsQuery();

  return useInfiniteQuery({
    queryKey: ['crm'],
    queryFn: async ({ pageParam = INITIAL_PAGE }) => {
      const res = await getCrmContacs({
        page: pageParam,
        limit: INITIAL_LIMIT,
      }).unwrap() as any;
      return { pageParam, ...res };
    },
    getNextPageParam: (lastPage) => {
      return lastPage.last
        ? undefined
        : (lastPage.pageParam || INITIAL_PAGE) + 1;
    },
    onSuccess: (data) => {
      const pages = data.pages;
      if (!Array.isArray(pages)) return;
    },
    keepPreviousData: true,
    refetchInterval: 60000,
  });
};
