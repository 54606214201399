import styles from './CrmContactPage.module.scss';

import React from 'react';
import RefreshIcon from 'components/icons/Refresh';
import { Layout } from 'components/Layout/Layout';
import CrmContact from 'components/CrmContact';

function CrmContactPage(): JSX.Element {
  const handleRefreshClick = () => {
    window.location.reload();
  };

  return (
    <Layout hasMenu={false}>
      <Layout.TopBar>
        <div className={styles.wrap}>
          <Layout.Back />
          <div onClick={handleRefreshClick}>
            <RefreshIcon />
          </div>
        </div>
      </Layout.TopBar>
      <Layout.Content unsetTopPadding>
        <CrmContact />
      </Layout.Content>
    </Layout>
  );
}

export default CrmContactPage;
